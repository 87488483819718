import { configs } from '@/constants'
import { dispatch } from '@/store'
import { userAct } from '@/store/user.store'
import type { FormLogin, FormRegister } from '@/types/form'
import type { User } from '@/types/user'
import { createFormData, generateId } from '@/utils'
import { cookie, storage } from '@/utils/storage'

import axios from '../axios'
import { tryCatch } from '../catch'

export class AuthService {
  /**
   * Sign-In
   *
   * @param data FormLogin
   */
  static async signIn(data: FormLogin) {
    try {
      const [formData, headers] = createFormData(data)
      const response = await axios.post('/v1/auth/login', formData, {
        headers
      })

      if (response.data?.status) {
        const setCookie = response.headers['set-cookie']
        cookie.set(configs.APP_AUTH_ACCESS, response.data?.sessionId || setCookie?.[1] || generateId())
      }

      return response.data
    } catch (error) {
      tryCatch('`AuthService.signIn`', error)
    }
  }

  /**
   * Sign-In
   *
   * @param data FormRegister
   */
  static async signUp(data: FormRegister) {
    try {
      const [formData, headers] = createFormData(data)
      const response = await axios.post('/v1/auth/register', formData, {
        headers
      })

      return response.data
    } catch (error) {
      tryCatch('`AuthService.signIn`', error)
    }
  }

  /**
   * Destroy all browser session.
   *
   * @param cb Callback function.
   */
  static async signOut(cb?: Function) {
    cookie.remove(configs.APP_AUTH_ACCESS)
    cookie.remove(configs.APP_AUTH_REFRESH)
    storage.remove(configs.APP_USER_INFO)

    dispatch(userAct.reset(0))

    if (cb) cb()

    axios.get(`/v1/logout`)
  }

  /**
   * GET user profile.
   */
  static async profile() {
    try {
      const [{ data: profile }, { data: balance }] = await Promise.all([
        axios.get<{ data: User }>(`/v1/users/profile`),
        axios.get(`/v1/user/refresh_balance`)
      ])

      if (profile.data) {
        dispatch(
          userAct.setProfile({
            ...profile.data,
            ...balance.data
          })
        )

        return true
      }
    } catch (error) {
      tryCatch('`AuthService.profile`', error)
    }
  }
}
